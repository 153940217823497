<template>
  <b-container fluid>
      <div class="card card-primary card-outline">
        <div class="card-header">
          <h3 class="card-title">Gutschrift</h3>
          <div class="card-tools">
            <router-link class="btn btn-primary btn-sm" :to="{name: 'credits.create'}" v-if="$auth.check('credits.create')"><i class="fas fa-plus-circle"></i> Neue Gutschrift</router-link>
          </div>
        </div>

        <div class="card-body">
            <div class="table-responsive">
                <table class="table table-striped table-bordered table-sm" v-if="notEmptyObject(credits) && credits.data.length >= 1">
                    <thead>
                    <td>Datum</td>
                    <td>Gutschrift-Nr.</td>
                    <td>Empfänger</td>
                    <td>Betrag</td>
                    <td style="width: 10%;">Status</td>
                    <td>Aktion</td>
                    </thead>

                    <tbody>
                    <tr v-for="(credit,index) in credits.data" :key="credit.id">         
                        <td>{{ credit.created_at | formatDateTime }}</td>
                        <td>{{ credit.credit_number }}</td>
                        <td>
                            <template v-if="credit.company != null && credit.company != ''">{{ credit.company }}<br></template>
                            <template v-if="credit.department != null && credit.department != ''">{{ credit.department }}<br></template>
                            <template v-if="credit.first_name != null && credit.first_name != ''">{{ credit.first_name }} {{ credit.last_name }}<br></template>
                            {{ credit.street }} {{ credit.house_number }}<br>
                            {{ credit.zipcode }} {{ credit.city }}
                        </td>
                        <td>{{ credit.invoice_amount | toCurrency}}</td>
                        <td>
                            <div v-if="selectedRow[index] && selectedCell === 'status_id'" class="input-group">
                                <select class="form-control form-control-sm" v-model="credits.data[index].status_id" @change="changeStatus('order', index)" v-if="$auth.check('credits.edit')">
                                    <option v-for="state in creditStates" :key="state.id" :value="state.id">{{ state.description }}</option> 
                                </select>
                                <div class="input-group-append">
                                    <!-- <button class="btn btn-sm btn-primary" type="button" @click="changeStatus('order', index)"><i class="fas fa-save"></i></button> -->
                                    <button class="btn btn-sm btn-secondary" type="button" @click="handleEditCell(index, 'status_id')"><i class="fas fa-times"></i></button>
                                </div>
                            </div>
                            <span @dblclick="handleEditCell(index, 'status_id')" v-else class="badge" :class="setOrderBadge(credit.status.description)">{{ credit.status.description }}</span>
                            
                        </td>
                        <td>
                            <button type="button" v-if="$auth.check('credits.storno') && credit.storno == null" class="mr-1 btn btn-primary btn-sm" @click="doStorno(credit)"><i class="fas fa-fw fa-ban"></i></button>
                            <router-link class="mr-1 btn btn-success btn-sm" :to="{name: 'credits.show', params: {id: credit.id}}" v-if="$auth.check('credits.show')"><i class="fas fa-fw fa-eye"></i></router-link>
                            <!-- <button v-if="credit.storno == null" type="button" class="mr-1 btn btn-warning btn-sm" @click="downloadPdf(credit)"><i class="fas fa-fw fa-download"></i></button> -->
                            <div class="btn-group">
                                <button @click="downloadPdf(credit)" type="button" class="btn btn-warning btn-sm"><i class="fas fa-fw fa-download"></i></button>
                                <button type="button" class="btn btn-warning btn-sm dropdown-toggle dropdown-icon" data-toggle="dropdown">
                                    <span class="sr-only">Toggle Dropdown</span>
                                </button>
                                <div class="dropdown-menu" role="menu">
                                    <a class="dropdown-item" @click="downloadPdf(credit)" href="#">Download (Gutschrift)</a>
                                    <a v-if="credit.storno != null" class="dropdown-item" @click="downloadStorno(credit)" href="#">Download (Storno)</a>
                                    <a v-if="credit.status.name != 'send' && credit.distributor_id != null" class="dropdown-item" @click="sendCredit(credit)" href="#">E-Mail (Storno)</a>
                                </div>
                            </div>
                            <!-- <button v-if="credit.customer_id != null" type="button" class="btn btn-primary btn-sm" @click="sendCredit(credit)"><i class="fas fa-fw fa-envelope"></i></button> -->
                        </td>
                    </tr>
                    </tbody>
                </table>
                <span v-else><h5>Derzeit sind keine Gutschriften im System gespeichert.</h5></span>
            </div>

            <div class="card-footer">
                <pagination v-if="notEmptyObject(credits)" class="float-left" :data="credits" @pagination-change-page="getCredits" :limit="3"></pagination>
                
                <select class="float-right form-control-sm" v-model="params.per_page">
                    <option value="25">25 Einträge</option>
                    <option value="50">50 Einträge</option>
                    <option value="75">75 Einträge</option>
                    <option value="100">100 Einträge</option>
                    <option value="125">125 Einträge</option>
                    <option value="150">150 Einträge</option>
                </select>
                <p v-if="notEmptyObject(credits)" class="float-right mr-2">Anzeige Eintrag {{ credits.meta.from }} - {{ credits.meta.to }} von {{ credits.meta.total }}</p>
            </div>
        </div>
      </div>

      <b-modal :no-enforce-focus="true" id="mailOrderModal" title="E-Mail an den Kunden senden" ok-variant="primary" ok-title="E-Mail senden" cancel-title="Abbrechen" size="xl" @ok="sendNotification">
            <div class="row">
                <div class="col-md-12">
                    <div class="form-group">
                        <label for="">An:</label>
                        <input type="email" class="form-control" v-model="orderModal.email"/>
                    </div>

                    <div class="form-group">
                        <label for="">Betreff:</label>
                        <input type="text" class="form-control" v-model="orderModal.subject"/>
                    </div>

                    <div class="form-group">
                        <editor
                                v-bind:api-key="this.$tinyApiKey"
                                :init="{
                                    height: 400,
                                    menubar: true,
                                    plugins: [
                                    'code advlist autolink lists link image charmap print preview anchor',
                                    'searchreplace visualblocks code fullscreen',
                                    'insertdatetime media table paste code help wordcount'
                                    ],
                                    toolbar:
                                    'undo redo | formatselect | bold italic backcolor | \
                                    alignleft aligncenter alignright alignjustify | \
                                    bullist numlist outdent indent | removeformat | code | help'
                                }"
                                v-model="orderModal.message"
                              
                                />
                    </div>
                </div>
            </div>
        </b-modal>
  </b-container>
</template>

<script>
import Editor from "@tinymce/tinymce-vue";
// import ClassicEditor from '../../../assets/packages/ckeditor5/src/ckeditor';
import "tinymce/tinymce";
// import "./../../../assets/packages/tinymce-bootstrap";
import "tinymce/themes/silver";
import "tinymce/icons/default";
import "tinymce/skins/ui/oxide/skin.css";
import "tinymce/plugins/advlist";
import "tinymce/plugins/autolink";
import "tinymce/plugins/bootstrap";
import "tinymce/plugins/code";
import "tinymce/plugins/link";
import "tinymce/plugins/image";
import "tinymce/plugins/lists";
import "tinymce/plugins/charmap";
import "tinymce/plugins/print";
import "tinymce/plugins/preview";
import "tinymce/plugins/help";
import "tinymce/plugins/fullscreen";
import "tinymce/plugins/table";


export default {
  name: 'Credits',
  title: 'Gutschriften',

  data(){
    return {
        editor: Editor,
        editorConfig: {
            simpleUpload: {
                uploadUrl: process.env.VUE_APP_API_URL + 'images/upload',
                withCredentials: true,
                headers: {
                    'Accept': 'application/json',
                    'Authorization': 'Bearer '+ this.$auth.token()
                }
            }
        },
			
        credits: [],
        params: {
                sort_field: 'distributornumber',
                sort_direction: 'desc',
                per_page: 25,
            },
            page: 1,
        selectedRow: {},
        selectedCell: null,
        orderModal: {
            message: '',
            subject: '',
            email: '',
            company_id: '',
            attachment: null,
        },
        }
  },

  methods: {
    doStorno(credit){
        this.$swal({
                    title: "Möchtest du die Gutschrift wirklich stornieren?",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: `Ja, STORNIEREN`,
                    denyButtonText: `Abbrechen`,
                })
                .then((result) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (result.isConfirmed) {
                        //Send request to the Server
                        this.axios
                            .post("/credits/" + credit.id + '/storno')
                            .then(() => {
                                this.$swal({
                                    icon: "success",
                                    title: "Gutschrift storniert!",
                                    toast: true,
                                    position: 'top-end',
                                    showConfirmButton: false,
                                    timer: 2000,
                                    timerProgressBar: true,
                                });
                                // this.$router.push({name: 'boards.index'});
                                this.getCredits();
                            })
                            .catch((error) => {
                                var message = 'Da ist etwas schief gelaufen'; 
                                if('error' in error.response.data)
                                {
                                    message = error.response.data.error; 
                                }
                                this.$swal({
                                    icon: "error",
                                    title: "Oops...",
                                    text: message, 
                                });
                            });
                    }
                });
    },

    changeStatus(mode, order)
        {
            var orders = [this.credits.data[order].id];
            //var status = {};
  
            // status = this.orderStates.find(state => state.id == this.credits[order].status_id);
            

            this.axios
                .post("/credits/changeStatus", {
                    orders: orders,
                    mode: mode,
                    status_id: (mode == 'order') ? this.credits.data[order].status_id : this.credits.data[order].status_id,
                    print: 0
                })
                .then(() => {
                    this.$swal({
                        icon: "success",
                        title: "Status erfolgreich geändert!",
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 2000,
                        timerProgressBar: true,
                        });
                    // if(status.mail == 1)
                    // {
                    //     this.sendMail(this.orders.data[order], status);
                    // }
                    this.handleEditCell(order, 'status_id')
                    this.getCredits();
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                });
        },


    handleEditCell(index, name) {
            this.selectedCell = name;
            this.selectedRow = {
            [index]: !this.selectedRow[index]
            }
        },

    setOrderBadge(state){

            if(state == 'Offen')
            {
                return 'badge-light'
            }

            if(state == 'Erstattet')
            {
                return 'badge-success'
            }

            if(state == 'Gesendet')
            {
                return 'badge-warning'
            }

            if(state == 'Fehler beim Versand')
            {
                return 'badge-danger'
            }

            if(state == 'Storniert')
            {
                return 'badge-danger'
            }

            return 'badge-primary';
        },


    sendNotification(bvModalEvt){
            bvModalEvt.preventDefault();
            this.$Progress.start();
            this.axios
            .post("/mail/send/credit", {
                'email': this.orderModal.email,
                'subject': this.orderModal.subject,
                'content': this.orderModal.message,
                'company_id': this.orderModal.company_id,
                'document_id': this.orderModal.attachment,
            })
            .then(() => {
            this.$Progress.finish();
            this.orderModal.attachment = null;
            this.getCredits();
            this.$bvModal.hide('mailOrderModal');
            this.$swal({
                icon: "success",
                title: "E-Mail gesendet",
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 2000,
                timerProgressBar: true,
                });
            })
            .catch(() => {
                this.$swal({
                    icon: "error",
                    title: "Oops...",
                    text: "Da ist etwas schief gelaufen",
                });
                this.$Progress.fail();
            });
        },

        sendCredit(credit){
            //console.log(status.name);
            this.$Progress.start();
            this.axios
            .post("/credits/mail/send", {
                'order_id': credit.id,
                'company_id': credit.company_id,
            })
            .then((response) => {
                this.$Progress.finish();
                this.orderModal.message = response.data.data.message;
                this.orderModal.subject = 'Deine Gutschrift im ' + credit.business.name;
                this.orderModal.email = credit.customer != null ? credit.customer.email : credit.distributor.credit_email;
                this.orderModal.company_id = credit.company_id;
                this.orderModal.attachment = credit.id;
                this.$bvModal.show('mailOrderModal');
            })
            .catch(() => {
                this.$swal({
                    icon: "error",
                    title: "Oops...",
                    text: "Da ist etwas schief gelaufen",
                });
                this.$Progress.fail();
            });
        },

    downloadStorno(credit){
        this.axios
        .get("/credits/" + credit.id + "/download/storno", {responseType: 'arraybuffer'})
        .then((response) => {
            //this.orders = response.data.data;
            this.downloadFile(response, credit, 'Storno_Gutschrift')
            this.$Progress.finish();
        })
        .catch(() => {
            this.$swal({
                icon: "error",
                title: "Oops...",
                text: "Da ist etwas schief gelaufen",
            });
            this.$Progress.fail();
        });
    },

    downloadPdf(credit){
      this.axios
      .get("/credits/" + credit.id + "/download", {responseType: 'arraybuffer'})
      .then((response) => {
          //this.orders = response.data.data;
          this.downloadFile(response, credit, 'Gutschrift')
          this.$Progress.finish();
      })
      .catch(() => {
          this.$swal({
              icon: "error",
              title: "Oops...",
              text: "Da ist etwas schief gelaufen",
          });
          this.$Progress.fail();
      });
    },

    downloadFile(response, credit, name){
        var newBlob = new Blob([response.data], {type: 'application/pdf'})

        // IE doesn't allow using a blob object directly as link href
        // instead it is necessary to use msSaveOrOpenBlob
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(newBlob)
          return
        }

        // For other browsers:
        // Create a link pointing to the ObjectURL containing the blob.
        const data = window.URL.createObjectURL(newBlob)
        var link = document.createElement('a')
        link.href = data
        link.download = name + '_' + credit.credit_number + '.pdf'
        link.click()
        setTimeout(function () {
            // For Firefox it is necessary to delay revoking the ObjectURL
            window.URL.revokeObjectURL(data)
        }, 100)
    },

    getCreditStates(){
            this.$Progress.start();
            this.axios
                .get("/states/credit")
                .then((response) => {
                    this.creditStates = response.data.data;
                    this.$Progress.finish();
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                    this.$Progress.fail();
                });
        },

    getCredits(page){
        if(page == undefined)
        {
            page = this.page 
        }
        this.axios
            .get("/credits", {
                params:{
                    page: page,
                    ...this.params
                }
            })
            .then((response) => {
                this.credits = response.data;
            })
            .catch(() => {
                this.$swal({
                    icon: "error",
                    title: "Oops...",
                    text: "Da ist etwas schief gelaufen",
                });
            });
    }
  },

  created(){
    this.getCredits();
    this.getCreditStates();
  }

}
</script>

<style>

</style>